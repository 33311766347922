import { useClerk } from "@clerk/clerk-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AnalysisContainer from "../components/AnalysisContainer/AnalysisContainer";
import { DividerSmall } from "../components/AnalysisSelector/AnalysisSelector";
import { InfoButton } from "../components/InfoTab/InfoTab";
import Spinner from "../components/Spinner/Spinner";
import ButtonIcon from "../components/buttons/ButtonIcon/ButtonIcon";
import ButtonTextIcon from "../components/buttons/ButtonTextIcon/ButtonTextIcon";
import { NIOSH, NONE, OCRA } from "../config/generalConst";
import { colors } from "../config/style";
import { tips } from "../config/tips";
import { decompressObjectLZ } from "../remote/compressionFunctions";
import { getInspection, getInspections, getResults } from "../remote/structure";
import { fetchDataLeftRight, getInspectionTaskData } from "../remote/tasks";
import { CLEAR_DATA, LOAD_DATA } from "../stores/actions/DataAction";
import { DATA_A } from "../stores/actions/TypeActions";
import { useStateValue } from "../stores/services/StateProvider";
import { getToken } from "../userManagement/utilities";
import { getOcraColor } from "../utils/Protocols/OCRA/OCRA";
import { NIOSH_ID, OCRA_ID, protocols } from "../config/availableProtocols";
import { isPositiveInteger } from "../components/AnalysisContainer/AnalysisContainerFunctions";
import { getColorFromMapping } from "../utils/Protocols/commonFunction";

const ScoreIndicator = ({ score, side }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        width: "6vw",
        height: "2vw",
        background: getOcraColor(score)[1],
        color: getOcraColor(score)[0],
        fontFamily: "Atkinson Hyperlegible",
        fontSize: "0.9vw",
        fontWeight: "700",
        borderRadius: "0.3vw",
      }}
    >
      {score > 0 ? (
        <>
          {side} {score}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

const ModularScoreIndicator = ({ scores, label, getColor }) => {
  console.log("getColor", getColor);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "2.5vw",
        background: "white",
        border: `0.14vw solid ${colors.background}`,
        borderRadius: "0.31vw",
        padding: "0.43vw",
        boxSizing: "border-box",
        width: "8.4vw",
      }}
    >
      <p
        style={{
          color: colors.text,
          fontFamily: "Atkinson Hyperlegible",
          fontSize: "0.9vw",
          fontWeight: "300",
          boxSizing: "border-box",
          margin: "0",
          width: "20%",
        }}
      >
        {label}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: scores.length > 1 ? "space-between" : "center",
          width: "80%",
        }}
      >
        {scores.map((score) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: colors.disabledText,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.7vw",
                  fontWeight: "400",
                  boxSizing: "border-box",
                  margin: "0",
                }}
              >
                {score.label}
              </p>
              <p
                style={{
                  color: getColor(score.score)[0],
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "0.95vw",
                  fontWeight: "700",
                  boxSizing: "border-box",
                  margin: "0",
                }}
              >
                {score.score >= 0 ? score.score : "--"}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NameColumn = ({ task, header, result, onClick, img, id }) => {
  const [hover, setHover] = useState(false);
  const { t } = useTranslation();

  const [imgSrc, setImgSrc] = useState(
    img ? img : process.env.REACT_APP_RESOURCES_BUCKET + "TaskPlaceholder.png"
  );

  const handleError = () => {
    setImgSrc(process.env.REACT_APP_RESOURCES_BUCKET + "TaskPlaceholder.png");
  };

  return [
    <div
      key={task?.id + task?.inspection + id}
      onMouseEnter={() => result && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => result && onClick()}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1.2,
        cursor: result ? "pointer" : "not-allowed",
      }}
    >
      {header ? (
        <>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Task.png"}
            style={{
              width: "1.4vw",
              height: "1.4vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{t("mainStructureTaskList")}</b>
          </p>
        </>
      ) : (
        <>
          <div
            style={{
              width: "3.1vw",
              height: "3.1vw",
              borderRadius: "0.2vw",
              marginRight: "0.3vw",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={imgSrc}
              style={{
                height: "3.1vw",
              }}
              onError={handleError}
            />
          </div>
          <p
            style={{
              width: "6vw",
              color: hover ? colors.main : colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              textDecoration: result && "underline",
              cursor: result && "pointer",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
            }}
          >
            {task.name}
          </p>
        </>
      )}
    </div>,
  ];
};

const StatusColumn = ({ header, err, data, exp, protocol, result }) => {
  const { t } = useTranslation();

  function getColors(err, exp, protocol) {
    if (protocol?.ref == protocols[NIOSH_ID].ref) {
      if (result?.taskDetail?.niosh?.m)
        return [colors.positivePale, colors.positive, t("completeStatus")];
      else
        return [
          colors.riskUnsurePale,
          colors.riskUnsure,
          t("incompleteStatus"),
        ];
    } else {
      if (err == 5) {
        if (exp == "expert")
          return [
            colors.positivePale,
            colors.positive,
            data ? formattaData(data, t, true) : t("completeStatus"),
          ];
        else if (exp == "reviewer" || exp == "dataset")
          return [
            colors.positivePale,
            colors.positive,
            data ? formattaData(data, t, true) : "Revisionare",
          ];
        else return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 7) {
        return [
          colors.positivePale,
          colors.positive,
          data ? formattaData(data, t, true) : t("completeStatus"),
        ];
      }
      if (err == 6) {
        return [
          colors.riskUnsurePale,
          colors.riskUnsure,
          t("incompleteStatus"),
        ];
      }
      if (err == 3) {
        return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 2) {
        return [colors.neutralPale, colors.neutral, t("missingStatus")];
      }
      if (err == -1) {
        return [colors.mainOpaque, colors.main, t("queueStatus")];
      }
      if (err == 4) {
        if (exp == "reviewer" || exp == "dataset")
          return [colors.mainOpaque, colors.main, t("reviewStatus")];
        else if (exp == "expert")
          return [
            colors.riskUnsurePale,
            colors.riskUnsure,
            t("incompleteStatus"),
          ];
        else return [colors.mainOpaque, colors.main, t("processingStatus")];
      }
      if (err == 1) {
        return [colors.negativePale, colors.negative, t("errorStatus")];
      } else return [colors.positivePale, colors.positive, "Unkno"];
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 0.8,
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              // width: columnWidth,
              flex: 1,
            }}
          >
            {t("mainStructureStatus")}
          </p>
        </>
      ) : (
        <div
          style={{
            width: "6.25vw",
            padding: "0.23vw",
            boxSizing: "border-box",
            background: getColors(err, exp, protocol)[0],
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            borderRadius: "0.78vw",
            gap: "0.23vw",
          }}
        >
          <div
            style={{
              width: "0.94vw",
              height: "0.94vw",
              borderRadius: "0.94vw",
              background: getColors(err, exp, protocol)[1],
            }}
          ></div>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.82vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {getColors(err, exp, protocol)[2]}
          </p>
        </div>
        // <>
        //   <img
        //     src={img}
        //     style={{
        //       width: "6.25vw",
        //       // height: "3.1vw",

        //       marginRight: "0.3vw",
        //     }}
        //   />
        // </>
      )}
    </div>
  );
};

const WorkstationColumn = ({ task, header }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      {header ? (
        <>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
            style={{
              width: "1.4vw",
              height: "1.4vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {t("workspace")}
          </p>
        </>
      ) : (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {task.stationName ? task.stationName : task.ws}
          </p>
        </>
      )}
    </div>
  );
};

const LineColumn = ({ task, header }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      {header ? (
        <>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Table.png"}
            style={{
              width: "1.4vw",
              height: "1.4vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {t("area")}
          </p>
        </>
      ) : (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            {task.line}
          </p>
        </>
      )}
    </div>
  );
};

const DurationColumn = ({
  task,
  header,
  onChange,
  jobDuration,
  errPerc,
  noTime,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslation();

  if (header)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          flex: 1,
        }}
      >
        <img
          src={process.env.REACT_APP_RESOURCES_BUCKET + "Time.png"}
          style={{
            width: "1.4vw",
            height: "1.4vw",
            marginLeft: "-0.3vw",
            marginRight: "0.3vw",
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", height: "80%" }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginTop: "0.9vw",
            }}
          >
            {t("duration") + " (%)"}
          </p>

          <p
            style={{
              // position: "relative",
              // marginTop: "2.6vw ",
              // marginLeft: "1.4vw",
              margin: 0,
              color: colors.negative,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.7vw",
              fontWeight: "400",
              boxSizing: "border-box",
              visibility: errPerc ? "visible" : "hidden",
            }}
          >
            {t("different100")}
          </p>
        </div>
      </div>
    );

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (value == "") {
      onChange(task.id, 0);
    }
    if (isPositiveInteger(value)) {
      if (parseInt(value) <= 100) {
        value = value ? parseInt(value) : 0;

        onChange(task.id, value);
      }
    }
  };

  const borderColor = () => {
    if (isFocused) return colors.main; // Blu quando è in focus
    if (!task.durata || errPerc) return colors.negative; // Rosso se il valore è vuoto
    return colors.background; // Colore di default se non in focus e non vuoto
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: jobDuration != -1 ? "space-between" : "center",
          width: "7.65vw",
          height: "2.5vw",
          background: "white",
          border: `0.14vw solid ${borderColor()}`,
          borderRadius: "0.31vw",
          padding: "0",
          boxSizing: "border-box",
          minWidth: "6.25vw",
        }}
      >
        {jobDuration != -1 ? (
          <input
            type="text"
            value={task.durata}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              width: "40%",
              height: "100%",
              border: "none",
              outline: "none",
              textAlign: "end",
              boxSizing: "border-box",
              backgroundColor: "transparent",
              marginRight: "0vw",
            }}
          />
        ) : (
          <></>
        )}
        <span
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            border: "none",
            outline: "none",
            textAlign: jobDuration != -1 ? "center" : "start",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            marginRight: jobDuration != -1 ? "0.5vw" : "0",
          }}
        >
          {jobDuration != -1 ? "%" : task.durata + "%"}
        </span>
        {jobDuration != -1 ? (
          <p
            style={{
              color: colors.disabledText,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              width: "65%",
              marginRight: "0vw",
            }}
          >
            ({Math.round((jobDuration * task.durata) / 100) + " "}
            m)
          </p>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const ScoreColumn = ({ result, header, open, protocol }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 2,
        gap: "0.2vw",
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginRight: "0.2vw",
            }}
          >
            {t("mainStructureScore")}
          </p>

          <InfoButton
            ai={false}
            err={false}
            std={true}
            info={tips[document.language].punteggio_parziale}
          />
        </>
      ) : (
        protocol && (
          <>
            {result &&
              protocol?.category?.map((x, id) => {
                return (
                  <ModularScoreIndicator
                    scores={protocol?.score?.map((score, id) => {
                      return {
                        score: result?.taskDetail?.[protocol.ref]?.[x]?.[score],
                        label: protocol?.topLabel?.[id],
                      };
                    })}
                    label={protocol?.labels[id]}
                    getColor={(value) =>
                      getColorFromMapping(value, protocol.mapping)
                    }
                  />
                );
              })}
          </>
        )
      )}
    </div>
  );
};

const ProtocolColumn = ({
  availableProtocols,
  header,
  protocol,
  setProtocol,
}) => {
  console.log("availableProtocols", availableProtocols);

  const { t } = useTranslation();

  const ToggleElement = ({ on, label, onClick }) => {
    const [hover, setHover] = useState(false);
    return (
      <div
        onClick={() => onClick()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          background: on ? "white" : "transparent",
          width: "1.85vw",
          height: "1.85vw",
          borderRadius: "50%",
          cursor: on ? "default" : "pointer",
          boxShadow: on
            ? "0vw 0.2vw 0.45vw 0vw rgba(113, 109, 242, 0.25)"
            : "0vw 0vw 0vw 0vw rgba(113, 109, 242, 0.25)",
        }}
      >
        <p
          style={{
            color: on || hover ? colors.main : colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.7vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0",
          }}
        >
          {label}
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        flex: 0.8,
        gap: "0.2vw",
      }}
    >
      {header ? (
        <>
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              marginRight: "0.2vw",
            }}
          >
            {t("protocol")}
          </p>

          <InfoButton
            ai={false}
            err={false}
            std={true}
            info={tips[document.language].protocollo}
          />
        </>
      ) : (
        availableProtocols && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: colors.background,
              padding: "0.3vw",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "2vw",
              gap: "0vw",
            }}
          >
            {availableProtocols &&
              availableProtocols.map((x, id) => {
                return (
                  <ToggleElement
                    label={x.short}
                    on={id == protocol}
                    onClick={() => setProtocol(id)}
                  />
                );
              })}
          </div>
        )
      )}
    </div>
  );
};

const ControlColumn = ({
  task,
  header,
  result,
  onClick,
  selected,
  deleteBtn,
  deleteSelected,
  unlinkIcon,
}) => {
  const { t } = useTranslation();

  console.log("taskdsdasdasdsd", task);

  return (
    <>
      {header ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            flex: 0.4,
            width: "1vw",
          }}
        >
          <div>
            <ButtonTextIcon
              label={
                unlinkIcon
                  ? t("mainStructureRemoveTasks")
                  : t("mainStructureRemoveSelection")
              }
              color={colors.negative}
              backgroundH={colors.negativePale}
              background={"transparent"}
              icon={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkNoHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHwNobg.png"
              }
              iconH={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkNoHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHwNobg.png"
              }
              onClick={() => {
                deleteSelected();
              }}
              style={{
                fontSize: "0.8vw",
                boxShadow: "0px 0px 0px 0px white",
                height: "2vw",
                width: "9.5vw",
                visibility:
                  selected.every((element) => element === false) && "hidden",
              }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            flex: 0.4,
          }}
        >
          {result && (
            <ButtonIcon
              icon={process.env.REACT_APP_RESOURCES_BUCKET + "Magnifier.png"}
              iconH={process.env.REACT_APP_RESOURCES_BUCKET + "MagnifierHw.png"}
              onClick={() => {
                onClick();
              }}
              style={{
                width: "1.875vw",
                // visibility: errors < 4 ? "hidden" : "visible",
              }}
            />
          )}

          {deleteBtn && (
            <ButtonIcon
              icon={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "Unlink.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "Delete.png"
              }
              iconH={
                unlinkIcon
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "UnlinkHw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "DeleteHw.png"
              }
              onClick={() => {
                deleteBtn([task.idLink]);
              }}
              style={{ width: "1.875vw" }}
            />
          )}
        </div>
      )}
    </>
  );
};

export const TaskTable = ({
  task,
  taskIndex,
  selected,
  setSelected,
  duration,
  columns,
  errPerc,
  deleteBtn,
  excludeControl,
  onChangeTime,
  onSave,
  loaded,
  setLoaded,
  unlinkIcon,
  dividerMargin,
}) => {
  const [state, dispatch] = useStateValue();
  const [result, setResult] = useState();
  const [editing, setEditing] = useState(false);
  const [status, setStatus] = useState({
    err: 2,
    cover: "",
    open: false,
  });
  const [cover, setCover] = useState();
  const [selectedTask, setSelectedTask] = useState();
  const [availableProtocols, setAvailableProtocols] = useState();
  const [protocol, setProtocol] = useState(0);

  const { session, user } = useClerk();

  console.log("results", result);

  useEffect(() => {
    if (onSave) loadResult(task);
  }, []);

  useEffect(() => {
    if (state.current_data == NONE && editing) {
      setEditing(false);
      loadResult(task);
    }
  }, [task]);

  useEffect(() => {
    if (state.current_data != NONE) {
      setEditing(true);
    }
  }, [state.current_data]);

  const loadResult = async (task) => {
    let resultTemp;
    let errorTemp;
    let localAvailableProtocols = [];
    // Inizializza protocollo
    protocols.forEach((protocol, id) => {
      if (
        task.hasOwnProperty(protocol.ref) ||
        (protocol.ref == protocols[OCRA_ID].ref &&
          (task?.last_result_obj || task?.last_result))
      ) {
        localAvailableProtocols.push(protocol);
      }
    });
    setAvailableProtocols(localAvailableProtocols);

    // NIOSH
    if (
      localAvailableProtocols.some(
        (protocol) => protocol.ref === protocols[1].ref
      )
    ) {
      resultTemp = { ...resultTemp, taskDetail: task };
    }

    // OCRA già dati: Se c'è già l'oggetto scaricato non andare a scaricare il risultato
    if (task?.last_result_obj) {
      let res = task.last_result_obj;
      let err = getStatus(res.ai.LEFT, res.ai.RIGHT, res.ai.HANDS, task.scores);
      let ocraValid =
        (err == 4 || err == 5) && !user?.publicMetadata?.expertise
          ? false
          : true;
      setStatus({
        err: err,
        cover: getImage(res.ai.LEFT.id, err),
        open:
          (err == 4 || err == 5) && !user?.publicMetadata?.expertise
            ? false
            : true,
      });
      if (err >= 4) {
        let resAdapted = {
          ...res,
          ai: {
            LEFT: res.ai.LEFT.id,
            RIGHT: res.ai.RIGHT.id,
            HANDS: res.ai.HANDS.id,
            edit: decompressObjectLZ(res.ai.edit),
          },
        };
        resultTemp = {
          ...resultTemp,
          ...resAdapted,
          taskDetail: task,
          ocraValid: ocraValid,
        };
        // setResult({ ...resAdapted, taskDetail: task });
      }
    } //OCRA da scaricare: In caso non c'è l'oggetto scaricalo insieme con i dati del task per ciascun video (usato per la struct)
    else if (task?.last_result) {
      let res = await getResults(task.last_result, await getToken(session));
      let resAdapted = {
        ...res,
        ai: {
          LEFT: res.ai.LEFT,
          RIGHT: res.ai.RIGHT,
          HANDS: res.ai.HANDS,
          edit: decompressObjectLZ(res.ai.edit),
        },
      };
      if (res?.ai?.LEFT) {
        let left = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.LEFT,
          await getToken(session)
        );
        let right = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.RIGHT,
          await getToken(session)
        );
        let hands = await getInspectionTaskData(
          res.inspection,
          res.task,
          res.ai.HANDS,
          await getToken(session)
        );
        let err = getStatus(left, right, hands, task.scores);
        setStatus({
          ...status,
          err: err,
          cover: getImage(res.ai.LEFT, err),
          open:
            (err == 4 || err == 5) && !user?.publicMetadata?.expertise
              ? false
              : true,
        });
        let ocraValid =
          (err == 4 || err == 5) && !user?.publicMetadata?.expertise
            ? false
            : true;
        if (err >= 4) {
          resultTemp = {
            ...resultTemp,
            ...resAdapted,
            taskDetail: task,
            ocraValid: ocraValid,
          };
        }
      }
    }
    if (resultTemp) setResult(resultTemp);
    setLoaded();
  };

  function getImage(id, error) {
    let imgAdress = process.env.REACT_APP_S3_BUCKET + id + "_obscured.jpg";
    if (error < 4) {
      return "";
    } else return imgAdress;
  }

  function getStatus(left, right, hands, scores) {
    if (
      left.status == "ERROR_OCRA_ANALYSIS" ||
      right.status == "ERROR_OCRA_ANALYSIS" ||
      hands.status == "ERROR_OCRA_ANALYSIS" ||
      left?.status?.includes("ERROR") ||
      right?.status?.includes("ERROR") ||
      hands?.status?.includes("ERROR")
    ) {
      return 1;
    }
    if (
      left.status == "PROCESSING" ||
      right.status == "PROCESSING" ||
      hands.status == "PROCESSING"
    ) {
      return 3;
    }
    if (
      left.status == "PENDING" ||
      right.status == "PENDING" ||
      hands.status == "PENDING"
    ) {
      return 2;
    }
    if (
      left.status == "QUEUE" ||
      right.status == "QUEUE" ||
      hands.status == "QUEUE"
    ) {
      return -1;
    }
    if (
      left.status == "REVIEWED" ||
      right.status == "REVIEWED" ||
      hands.status == "REVIEWED"
    ) {
      if (scores?.dx >= 0 && scores?.sx >= 0) return 7;
      return 6;
    }
    if (
      left.status == "COMPLETED" &&
      right.status == "COMPLETED" &&
      hands.status == "COMPLETED"
    ) {
      if (scores?.dx >= 0 && scores?.sx >= 0) return 5;
      return 4;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    if (selectedTask) {
      if (selectedTask?.ai?.LEFT) {
        fetchDataLeftRight(
          selectedTask.ai.LEFT,
          selectedTask.ai.RIGHT,
          selectedTask.ai.HANDS,
          selectedTask,
          (res) => {
            dispatch({ type: DATA_A, subtype: LOAD_DATA, action: res });
          },
          user?.publicMetadata?.expertise == "reviewer" ||
            user?.publicMetadata?.expertise == "dataset"
        );
      }
    }
  }, [selectedTask]);

  // CASO CLICK SU TASK
  if (selectedTask) {
    // CASO PRESENZA DI OCRA CONTROLLO DATI CARICATI
    if (selectedTask?.ocraValid) {
      // DATI NON CARICATI CORRETTAMENTE
      if (
        state.current_data.config == NONE ||
        state.current_data == NONE ||
        selectedTask?.checklist?.tempo_ciclo_osservato == -1
      )
        return (
          <div
            style={{
              position: "absolute",
              width: "100vw",
              height: "100vh",
              top: "0px",
              left: "0px",
              backgroundColor: colors.mainBackground,
            }}
          >
            <Spinner />
          </div>
        );
    }
    // TUTTO OK CARICA ANALISI
    return (
      <AnalysisContainer
        selectedTask={selectedTask}
        onClose={() => {
          dispatch({ type: DATA_A, subtype: CLEAR_DATA });
          onSave();
          setTimeout(() => {
            setSelectedTask(null);
          }, 2000);
        }}
      />
    );
  } else if (loaded)
    return (
      <>
        <div
          key={taskIndex}
          style={{
            display: "flex",
            justifyContent: "start",
            padding: "0.2vw 0",
            height: "3.8vw",
            width: "100%",
            paddingLeft: excludeControl ? "0.8vw" : "1.25vw",
            paddingRight: excludeControl ? "0.8vw" : "0.8vw",
            boxSizing: "border-box",
            alignItems: "center",
          }}
        >
          {deleteBtn && (
            <img
              onClick={() => setSelected()}
              src={
                selected
                  ? process.env.REACT_APP_RESOURCES_BUCKET + "checkboxhw.png"
                  : process.env.REACT_APP_RESOURCES_BUCKET + "checkbox.png"
              }
              style={{
                width: "1.2vw",
                height: "1.2vw",
                marginRight: "0.5vw",
                cursor: "pointer",
                filter:
                  selected &&
                  "drop-shadow(0px 0.4vw 0.8vw rgba(113, 109, 242, 0.30))",
              }}
            />
          )}
          {columns.map((col, id) => {
            if (col == "name")
              return (
                <NameColumn
                  id={taskIndex}
                  task={task}
                  result={result}
                  onClick={() => setSelectedTask(result)}
                  img={status.cover}
                />
              );
            if (col == "state")
              return (
                <StatusColumn
                  protocol={availableProtocols[protocol]}
                  result={result}
                  data={deleteBtn && task.inspection_date}
                  err={status.err}
                  exp={user?.publicMetadata?.expertise}
                />
              );
            if (col == "protocol")
              return (
                <ProtocolColumn
                  availableProtocols={availableProtocols}
                  protocol={protocol}
                  setProtocol={setProtocol}
                />
              );
            if (col == "ws")
              return <WorkstationColumn id={taskIndex} task={task} />;
            if (col == "line") return <LineColumn id={taskIndex} task={task} />;
            if (col == "duration")
              return (
                <DurationColumn
                  id={taskIndex}
                  task={task}
                  jobDuration={duration ? duration : 0}
                  errPerc={errPerc}
                  onChange={onChangeTime}
                />
              );
            if (col == "score")
              return (
                <ScoreColumn
                  id={taskIndex}
                  result={result}
                  open={status.open}
                  protocol={availableProtocols && availableProtocols[protocol]}
                />
              );
          })}
          {/* {columns.includes("name") && (
            <NameColumn
              id={taskIndex}
              task={task}
              result={result}
              onClick={() => setSelectedTask(result)}
              img={status.cover}
            />
          )}
          {columns.includes("state") && (
            <StatusColumn
              protocol={availableProtocols[protocol]}
              result={result}
              data={deleteBtn && task.inspection_date}
              err={status.err}
              exp={user?.publicMetadata?.expertise}
            />
          )}
          {columns.includes("protocol") && (
            <ProtocolColumn
              availableProtocols={availableProtocols}
              protocol={protocol}
              setProtocol={setProtocol}
            />
          )}
          {columns.includes("ws") && (
            <WorkstationColumn id={taskIndex} task={task} />
          )}
          {columns.includes("line") && (
            <LineColumn id={taskIndex} task={task} />
          )}
          {columns.includes("duration") && (
            <DurationColumn
              id={taskIndex}
              task={task}
              jobDuration={duration ? duration : 0}
              errPerc={errPerc}
              onChange={onChangeTime}
            />
          )}
          {columns.includes("score") && (
            <ScoreColumn
              id={taskIndex}
              result={result}
              open={status.open}
              protocol={availableProtocols && availableProtocols[protocol]}
            />
          )} */}
          {!excludeControl && (
            <ControlColumn
              id={taskIndex}
              task={task}
              result={result && status.open}
              onClick={() => setSelectedTask(result)}
              deleteBtn={deleteBtn}
              unlinkIcon={unlinkIcon}
            />
          )}
        </div>
        <DividerSmall
          margin={
            dividerMargin
              ? dividerMargin
              : excludeControl
              ? "0vw 0.8vw 0vw 0.8vw"
              : "0vw 0.8vw 0vw 1.25vw"
          }
        />
      </>
    );
};

function formattaData(dataISO, t, short) {
  const mesi = [
    t("gen"),
    t("feb"),
    t("mar"),
    t("apr"),
    t("may"),
    t("jun"),
    t("jul"),
    t("aug"),
    t("sep"),
    t("oct"),
    t("nov"),
    t("dec"),
  ];
  const data = new Date(dataISO);

  const giorno = data.getDate();
  const mese = mesi[data.getMonth()];
  const anno = data.getFullYear().toString();
  const annoShort = data.getFullYear().toString().slice(-2);

  const ore = data.getHours();
  const minuti = data.getMinutes().toString().padStart(2, "0");
  const secondi = data.getSeconds().toString().padStart(2, "0");

  if (short) return `${giorno} ${mese} '${annoShort}`;

  return `${giorno} ${mese} ${anno}  ${" - "}  ${ore}:${minuti}`;
}

export const JobTable = ({
  tasks,
  duration,
  jobIndex,
  header,
  width,
  columns,
  empty,
  deleteBtn,
  onChangeTime,
  date,
  onSave,
  setLoadedJobs,
  unlinkIcon,
  loadedJobs,
}) => {
  const [selected, setSelected] = useState(new Array(tasks.length).fill(false));
  const [loaded, setLoaded] = useState(0);

  const loadedRef = useRef();
  loadedRef.current = loaded;

  console.log("taskstaskstasks", tasks);

  let columnsNumber = columns.length + 1;
  let columnWidth = Math.ceil(width / columnsNumber);
  let errPerc =
    duration != -1
      ? tasks.reduce((acc, task) => acc + task.durata, 0) != 100 &&
        tasks.length > 0
      : false;

  const toggleSelected = (index) => {
    setSelected((prevSelected) => {
      // Copia l'array corrente per non mutare lo stato direttamente
      const newSelected = [...prevSelected];

      // Inverte il valore all'indice specificato
      newSelected[index] = !newSelected[index];

      // Ritorna il nuovo array
      return newSelected;
    });
  };
  useEffect(() => {
    setSelected(new Array(tasks.length).fill(false));
  }, [tasks]);

  useEffect(() => {
    if (loadedRef.current == tasks.length && setLoadedJobs) setLoadedJobs();
  }, [loaded]);

  const getSelection = () => {
    if (selected.every((element) => element === true) && tasks.length > 0)
      return process.env.REACT_APP_RESOURCES_BUCKET + "checkboxhw.png";
    if (selected.every((element) => element === false) || tasks.length == 0)
      return process.env.REACT_APP_RESOURCES_BUCKET + "checkbox.png";
    return process.env.REACT_APP_RESOURCES_BUCKET + "checkboxPartial.png";
  };

  const deleteSelected = () => {
    const filteredIds = tasks
      .filter((obj, index) => selected[index])
      .map((task) => task.id);
    loadedRef.current = loadedRef.current - filteredIds.length;
    setLoaded(loadedRef.current);
    deleteBtn(filteredIds);
  };
  console.log("columns", columns);
  return (
    <div
      key={jobIndex + date}
      style={{
        padding: "0px",
        marginTop: header && "1.5vw",
        width: "100%",
        height: "100%",
      }}
    >
      {" "}
      {header && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            paddingLeft: "1.25vw",
            paddingRight: "1.25vw",
          }}
        >
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Worker.png"}
            style={{
              width: "1.9vw",
              height: "1.9vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{header.name}</b>
          </p>
        </div>
      )}
      {tasks.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            height: "3.8vw",
            width: "100%",
            // marginLeft: "-1.25vw",
            borderTop: "0.1vw solid rgba(237, 235, 255, 1)",
            borderBottom: "0.1vw solid rgba(237, 235, 255, 1)",
            backgroundColor: colors.disabled,
            padding: "1.25vw",
            boxSizing: "border-box",
          }}
        >
          {deleteBtn && tasks.length > 0 && (
            <img
              onClick={() => {
                if (selected.every((element) => element === false)) {
                  setSelected(new Array(tasks.length).fill(true));
                } else {
                  setSelected(new Array(tasks.length).fill(false));
                }
              }}
              src={getSelection()}
              style={{
                width: "1.2vw",
                height: "1.2vw",
                marginRight: "0.5vw",
                filter:
                  selected &&
                  "drop-shadow(0px 0.4vw 0.8vw rgba(113, 109, 242, 0.30))",
              }}
            />
          )}

          {columns.map((col, id) => {
            if (col == "name") return <NameColumn header={true} />;
            if (col == "state") return <StatusColumn header={true} />;
            if (col == "protocol") return <ProtocolColumn header={true} />;
            if (col == "ws") return <WorkstationColumn header={true} />;
            if (col == "duration")
              return <DurationColumn header={true} errPerc={errPerc} />;
            if (col == "score") return <ScoreColumn header={true} />;
          })}

          <ControlColumn
            header={true}
            selected={selected}
            deleteSelected={deleteSelected}
            unlinkIcon={unlinkIcon}
          />
        </div>
      )}
      <div style={{ overflowY: "scroll", height: "100%" }}>
        {tasks.length > 0 ? (
          <>
            {loadedRef.current < tasks.length && (
              <div
                style={{ height: empty ? "100%" : 3.9 * tasks.length + "vw" }}
              >
                <Spinner size={"3vw"} />
              </div>
            )}
            {tasks.map((task, taskIndex) => (
              <TaskTable
                key={task.id}
                task={task}
                columns={columns}
                unlinkIcon={unlinkIcon}
                taskIndex={taskIndex}
                deleteBtn={
                  deleteBtn
                    ? (id) => {
                        loadedRef.current = loadedRef.current - 1;
                        setLoaded(loadedRef.current);
                        deleteBtn(id);
                      }
                    : false
                }
                width={columnWidth}
                selected={selected[taskIndex]}
                setSelected={() => toggleSelected(taskIndex)}
                duration={duration}
                errPerc={errPerc}
                onChangeTime={onChangeTime}
                onSave={onSave}
                loaded={loadedRef.current >= tasks.length}
                setLoaded={() => {
                  loadedRef.current = loadedRef.current + 1;
                  setLoaded(loadedRef.current);
                }}
              />
            ))}
          </>
        ) : (
          empty
        )}
      </div>
    </div>
  );
};

export const InspectionTable = ({
  inspection,
  inspectionIndex,
  onSave,
  owner,
}) => {
  const [open, setOpen] = useState(false);
  const [loadedJob, setLoadedJobs] = useState(0);
  const [inspectionDetails, setInspectionDetails] = useState();

  const loadedJobRef = useRef();
  loadedJobRef.current = loadedJob;

  const { session } = useClerk();

  const { t } = useTranslation();

  useEffect(() => {
    if (inspectionIndex == 0) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {}, [loadedJob]);

  useEffect(() => {
    if (open) {
      loadInspectionDetail();
    }
  }, [open]);

  const loadInspectionDetail = async (onSuccess) => {
    let token = await getToken(session);
    let res = await getInspection(inspection.id, token);

    setInspectionDetails(res);
  };

  function calcolaNumeroTotaleTasks(dati) {
    let totaleTasks = 0;

    // Itera attraverso ogni job nell'oggetto 'jobs'
    for (const jobId in dati.jobs) {
      const job = dati.jobs[jobId];

      // Controlla se 'tasks' esiste e è un array
      if (Array.isArray(job.tasks)) {
        totaleTasks += job.tasks.length;
      }
    }

    return totaleTasks;
  }

  return (
    <div
      key={inspection.id + inspectionIndex}
      style={{
        marginBottom: "1vw",
        background: "white",
        width: "72.8vw",
        borderRadius: "0.3vw",
        paddingTop: "0.5vw",
        paddingBottom: "0.5vw",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "1.25vw",
          paddingRight: "1.25vw",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "1.0vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
              textDecoration: "underline",
              marginRight: "1vw",
            }}
          >
            {owner ? (
              <>
                {" "}
                <b>{inspection.ownerCompany}</b>{" "}
              </>
            ) : (
              <></>
            )}
          </p>
          <img
            src={process.env.REACT_APP_RESOURCES_BUCKET + "Factory.png"}
            style={{
              width: "1.9vw",
              height: "1.9vw",
              marginLeft: "-0.3vw",
              marginRight: "0.3vw",
            }}
          />
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "0.9vw",
              fontWeight: "400",
              boxSizing: "border-box",
              margin: "0",
            }}
          >
            <b>{inspection.department}</b> |{" "}
            {formattaData(inspection.creationDate, t)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            width: "10vw",
          }}
        >
          <ButtonTextIcon
            label={open ? t("close") : t("open")}
            color={colors.text}
            loading={open && !inspectionDetails}
            // loading={true}
            loadingLabel={" "}
            loadingStyle={{}}
            backgroundH={colors.details}
            background={"transparent"}
            icon={
              open
                ? process.env.REACT_APP_RESOURCES_BUCKET + "ArrowUpEmpty.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "ArrowDownEmpty.png"
            }
            iconH={
              open
                ? process.env.REACT_APP_RESOURCES_BUCKET + "ArrowUpEmpty.png"
                : process.env.REACT_APP_RESOURCES_BUCKET + "ArrowDownEmpty.png"
            }
            onClick={() => {
              setOpen(!open);
            }}
            style={{
              fontSize: "0.9vw",
              boxShadow: "0px 0px 0px 0px white",
              height: "2vw",
              fontWeight: "400",
              width: "5.5vw",
              justifyContent: open && !inspectionDetails ? "center" : "start",
            }}
          />
          {/* <ButtonIcon
            icon={
              process.env.REACT_APP_RESOURCES_BUCKET+"Delete.png"
            }
            iconH={
              process.env.REACT_APP_RESOURCES_BUCKET+"DeleteHw.png"
            }
            onClick={() => {}}
            style={{ width: "1.875vw" }}
          /> */}
        </div>
      </div>
      <DividerSmall margin={"0.25vw 1.25vw 0.25vw 1.25vw"} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "1.25vw",
          paddingRight: "1.25vw",
        }}
      >
        {" "}
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0vw",
          }}
        >
          {Object.keys(inspection.jobs).length} {t("mainStructureJobAnalyzed")}{" "}
          - {calcolaNumeroTotaleTasks(inspection)}{" "}
          {t("mainStructureTaskAnalyzed")}
        </p>
        <p
          style={{
            color: colors.text,
            fontFamily: "Atkinson Hyperlegible",
            fontSize: "0.9vw",
            fontWeight: "400",
            boxSizing: "border-box",
            margin: "0vw",
            marginLeft: "0.4vw",
          }}
        >
          {t("mainStructureCreatedBy")} {inspection.author}
        </p>
      </div>

      {open &&
        inspectionDetails &&
        Object.keys(inspectionDetails.jobs).map((jobKey, jobKeyIndex) => {
          let job = inspectionDetails.jobs[jobKey];
          return (
            <JobTable
              loadedJobs={
                loadedJobRef.current == Object.keys(inspection.jobs).length
              }
              key={job.id}
              tasks={job.tasks.map((item) => {
                return { ...item.task, durata: item.durata };
              })}
              jobIndex={jobKeyIndex}
              header={{ name: job.name }}
              columns={["name", "ws", "duration", "protocol", "state", "score"]}
              duration={-1}
              date={inspection.creationDate}
              onSave={onSave}
              setLoadedJobs={() => {
                loadedJobRef.current = loadedJobRef.current + 1;
                setLoadedJobs(loadedJobRef.current);
              }}
            />
          );
        })}
    </div>
  );
};

export const MainInspections = ({ dataTestId }) => {
  const [state, dispatch] = useStateValue();
  const [inspections, setInspections] = useState();
  const [loaded, setLoaded] = useState(false);

  const { session } = useClerk();

  const { t } = useTranslation();

  useEffect(() => {
    if (Object.keys(state.current_location).length != 0) loadInspection();
    setLoaded(true);
  }, [state.current_location]);

  const loadInspection = async (onSuccess) => {
    setInspections(); // Inizializza setInspections, forse vuoi passare un valore o lasciare così se si tratta di un reset
    try {
      let token = await getToken(session);
      let res = await getInspections(token);
      setInspections(ordinaPerDataCreazione(res)); // Imposta le ispezioni ordinate se la chiamata ha successo
      if (onSuccess) onSuccess(); // Chiama onSuccess se fornita e tutto va a buon fine
    } catch (error) {
      console.error("Errore durante la chiamata a getInspections:", error);
      setInspections(-1); // Gestisce l'errore impostando setInspections a false o ad un valore appropriato
    }
  };

  function ordinaPerDataCreazione(array) {
    const inspectionLocation = array.filter(
      (element) => element.locationId === state.current_location.id
    );

    return inspectionLocation.sort((a, b) => {
      // Converti le date in oggetti Date per il confronto
      let dataA = new Date(a.creationDate);
      let dataB = new Date(b.creationDate);

      // Ordina in modo crescente
      return dataB - dataA;
    });
  }

  return (
    <div
      style={{
        width: "88vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div
        style={{
          width: "73.5vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "10%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "0.8vw",
            boxSizing: "border-box",
          }}
        >
          <p
            style={{
              color: colors.text,
              fontFamily: "Atkinson Hyperlegible",
              fontSize: "1.6vw",
              fontWeight: "700",

              marginTop: "1.9vw",
              boxSizing: "border-box",
            }}
          >
            {t("inspection")}
          </p>

          <ButtonTextIcon
            icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
            label={t("refresh")}
            backgroundH={colors.mainH}
            background={colors.main}
            color={"white"}
            onClick={() => {
              loadInspection();
            }}
          />
        </div>

        <div
          style={{
            overflowY: "scroll",
            width: "73.5vw",
            height: "88vh",
            // padding: "2vw",
          }}
        >
          {" "}
          {(inspections == -1 || inspections?.length == 0) && loaded ? (
            <div
              style={{
                width: "72.8vw",
                height: "100%",
                borderRadius: "0.3vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "white",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_RESOURCES_BUCKET + "InspectionPh.png"
                }
                style={{
                  width: "23.5vw",
                }}
              />{" "}
              <p
                style={{
                  color: colors.text,
                  fontFamily: "Atkinson Hyperlegible",
                  fontSize: "1.25vw",
                  fontWeight: "400",
                  marginLeft: "0vw",
                  marginTop: "0vw",
                  boxSizing: "border-box",
                  textAlign: "center",
                  width: "25vw",
                  marginTop: "1vw",
                }}
              >
                {t("mainInspectionNoIsp")}
              </p>
              <ButtonTextIcon
                icon={process.env.REACT_APP_RESOURCES_BUCKET + "RefreshWh.png"}
                label={t("refresh")}
                backgroundH={colors.tertiaryH}
                background={colors.tertiary}
                color={"white"}
                onClick={() => {
                  loadInspection();
                }}
              />
            </div>
          ) : inspections ? (
            <div style={{}}>
              {inspections?.map((inspection, index) => {
                return (
                  <InspectionTable
                    key={inspection.id}
                    inspection={inspection}
                    inspectionIndex={index}
                    onSave={() => loadInspection()}
                  />
                );
              })}
            </div>
          ) : (
            <Spinner />
          )}
          {/* <InspectionTable inspections={inspections} /> */}
        </div>
      </div>
    </div>
  );
};
