export function manageAiResult(graphMemory, availableAnalysis) {
  let newResults = {
    // AZIONI T DX
    numero_azioni_dinamiche_dx: graphMemory?.[
      availableAnalysis[1].analysis.dynamicRight.id_local
    ]
      ? graphMemory?.[availableAnalysis[1].analysis.dynamicRight.id_local][0]
          .newValue
      : availableAnalysis[1].analysis.dynamicRight.value,

    // AZIONI T SX
    numero_azioni_dinamiche_sx: graphMemory?.[
      availableAnalysis[1].analysis.dynamicLeft.id_local
    ]
      ? graphMemory?.[availableAnalysis[1].analysis.dynamicLeft.id_local][0]
          .newValue
      : availableAnalysis[1].analysis.dynamicLeft.value,

    // MANO DX
    secondi_mano_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightHand.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightHand.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightHand.value,

    // MANO SX
    secondi_mano_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftHand.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftHand.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftHand.value,

    // SPALLA DX
    secondi_spalla_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightShoulder.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightShoulder.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightShoulder.value,

    // SPALLA SX
    secondi_spalla_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftShoulder.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftShoulder.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftShoulder.value,

    // POLSO DX
    secondi_polso_incongrua_dx: graphMemory?.[
      availableAnalysis[0].analysis.rightWrist.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.rightWrist.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.rightWrist.value,

    // POLSO SX
    secondi_polso_incongrua_sx: graphMemory?.[
      availableAnalysis[0].analysis.leftWrist.id_local
    ]
      ? graphMemory?.[availableAnalysis[0].analysis.leftWrist.id_local][0]
          .newValue
      : availableAnalysis[0].analysis.leftWrist.value,

    // GOMITO DX
    secondi_gomito_incongrua_dx:
      graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local]?.[0]
        ?.newValue &&
      graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local]?.[1]
        ?.newValue
        ? graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local][0]
            .newValue +
          graphMemory?.[availableAnalysis[0].analysis.rightElbow.id_local][1]
            .newValue
        : availableAnalysis[0].analysis.rightElbow.value,

    // GOMITO SX
    secondi_gomito_incongrua_sx:
      graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local]?.[0]
        ?.newValue &&
      graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local]?.[1]
        ?.newValue
        ? graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local][0]
            .newValue +
          graphMemory?.[availableAnalysis[0].analysis.leftElbow.id_local][1]
            .newValue
        : availableAnalysis[0].analysis.leftElbow.value,
  };
  return newResults;
  // setOcraMemory({ ...ocraMemory, ...newResults });
}

export function isPositiveInteger(numberString) {
  const number = Number(numberString);
  return (Number.isInteger(number) && number >= 0) || numberString == null;
}

export function formatDecimal(numberString) {
  const str = String(numberString);

  if (str === null || str.trim() === "") {
    return null;
  }
  let normalizedNumberString = str.replace(",", ".");
  // Controlla se l'ultimo carattere è un punto o una virgola
  const lastChar = normalizedNumberString.slice(-1);
  if (lastChar === ".") {
    // Verifica se ci sono già altri punti nella stringa
    const pointCount = (normalizedNumberString.match(/\./g) || []).length;
    if (pointCount > 1) {
      // Se c'è già un punto, rimuovi l'ultimo punto
      normalizedNumberString = normalizedNumberString.slice(0, -1);
    }
    return normalizedNumberString; // Restituisce la stringa così com'è se termina con un punto
  }

  // Sostituisci la virgola con il punto per la conversione

  const number = parseFloat(normalizedNumberString);
  if (isNaN(number)) {
    return null;
  }

  // Formatta il numero e usa la virgola come separatore decimale
  const formattedNumber = Number.isInteger(number)
    ? number.toFixed(0)
    : number.toFixed(1);

  return formattedNumber;
}
